<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.linkToSuggestion.label') }}
    </template>
    <custom-button-icon
      :class="buttonClass"
      color="secondary"
      round
      icon="actions/link"
      icon-class="text-2xl"
      @click="isShowSelectArgumentsModal = true"
    />
  </custom-tooltip>
  <modal-or-bottom-sheet
    v-model="isShowSelectArgumentsModal"
    :title="$t('suggestions.argumentsModal')"
    size="lg"
    :modal-props="{centered: false, hideFooter: true}"
  >
    <div class="flex flex-col gap-y-6">
      <div class="flex flex-col gap-y-3 items-start">
        <h2 class="text-base font-semibold">
          {{ $t('common.language') }}
        </h2>
        <toggle-language
          v-model="suggestionLanguage"
          is-general
        />
      </div>
      <div class="flex flex-col gap-y-3 items-start">
        <h2 class="text-base font-semibold">
          {{ $t('currency.label') }}
        </h2>
        <app-currency-toggle
          v-model="suggestionCurrency"
          is-general
        />
      </div>
    </div>
    <div class="w-full flex justify-end">
      <custom-button
        class="font-semibold whitespace-nowrap px-4 h-[38px] flex items-center"
        pill
        @click="copyLink"
      >
        <template #prefix>
          <nuxt-icon
            name="actions/link"
          />
        </template>
        {{ t('files.actions.link') }}
      </custom-button>
    </div>
  </modal-or-bottom-sheet>
</template>
<script setup lang="ts">
import { useClipboard } from '@vueuse/core'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { ManagingSuggestion } from '~/modules/suggestions/types/Suggestion'
import { COLOR } from '~/ui/constants/color'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'

import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import ToggleLanguage from '~/modules/app-language/components/ToggleLanguage.vue'
import AppCurrencyToggle from '~/components/app/AppCurrencyToggle.vue'
import { useAppStateStore } from '~/store/app'

const props = defineProps({
  suggestion: {
    type: Object as PropType<ManagingSuggestion>,
    required: true,
  },
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})
const nuxtApp = useNuxtApp()

const { t, locale: currentLocale } = useI18n()

const isShowSelectArgumentsModal = ref(false)

const suggestionLanguage = ref(currentLocale.value)

const suggestionCurrency = ref(useAppStateStore().currency)

const suggestionLink = computed(() => {
  const baseUrl = `${nuxtApp.$config.public.estateMarketAppUrl}/suggestions/${props.suggestion?.uniqueId}`
  const params = new URLSearchParams({
    lang: suggestionLanguage.value,
    currency: suggestionCurrency.value,
  })
  return `${baseUrl}?${params.toString()}`
})

const { copy } = useClipboard({ source: suggestionLink, legacy: true })

const copyLink = () => {
  copy(suggestionLink.value)
    .then(() => {
      toast(t('suggestionsActions.copyLink.toast.success'), { variant: COLOR.success })
      isShowSelectArgumentsModal.value = false
    })
}

watch(isShowSelectArgumentsModal, () => {
  if (!isShowSelectArgumentsModal.value) {
    suggestionLanguage.value = currentLocale.value
    suggestionCurrency.value = useAppStateStore().currency
  }
})

</script>
